import { useLocalFetch } from "~/composables/apiHandler.ts";
import { defineStore } from "pinia";
import { getCompanyInformation } from "~/connectables/fetch/companyInformation.ts";

export const useCompanyInfoStore = defineStore("getCompanyInformation", () => {
  const { $auth } = useNuxtApp();

  const companyInfo = useLocalFetch("getCompanyInformation", getCompanyInformation);
  async function isReady() {
    const authed = await $auth.isAuthed();
    if (authed) {
      if (companyInfo.initial.value) {
        // Don't await this because we will await it in a moment.
        companyInfo.fetch().then((_) => _);
      }
      if (companyInfo.pending.value) {
        // do the await this way so if we have already started, then it will listen to the promise;
        await companyInfo.promise.value;
      }
      if (companyInfo.resolved.value) {
        // now we are good to go.
        return true;
      }
    }
    return false;
  }
  return {
    isReady,
    ...companyInfo,
  };
});
