import { LocalFetchError } from "~/composables/apiHandler.ts";
import { useReportError } from "~/composables/reportError.ts";
import { isNull } from "assertate";
import type { MaybeRef, Ref } from "vue";
import { get, set, createStore, del } from "idb-keyval";

export enum NotificationType {
  Success = "success",
  Error = "error",
  Pending = "pending",
  Warning = "warning",
}

export class NotificationMessage {
  public id: string;
  public timeout?: number;
  public timestamp: string;
  public hasDownload?: boolean;
  public seen?: boolean;
  constructor(
    public type: NotificationType,
    public message: string,
    public active: boolean,
    public persist: boolean = false,
    public showProgress: boolean = false,
    public progress: number = 0,
  ) {
    this.id = getUID();
    this.timestamp = new Date().toISOString();
  }
}

export type GenericMessageObject = { message: string };

export function isMessageObject(value: unknown): value is GenericMessageObject {
  return typeof value === "object" && !isNull(value) && Object.hasOwn(value, "message");
}

export type ErrorNotificationOptions = MaybeRef<string | LocalFetchError | Error | GenericMessageObject>;

type StoredFile = {
  file: Blob;
  filename: string;
};

export interface NotificationHandle {
  messageObject: Ref<NotificationMessage>;
  close: () => void;
  hide: () => void;
  cancelTimeout: () => void;
  setTimeout: (val?: number) => void;
  persist: (val?: boolean) => void;
  setFile: (file: Blob, filename: string) => Promise<void>;
  getFile: () => Promise<StoredFile | void>;
  setType: (val: NotificationType) => void;
  setMessage: (val: string) => void;
  setProgress: (val: number) => void;
  hideProgress: () => void;
  markSeen: () => void;
}

export const useGlobalNotification = defineStore("GlobalNotification", () => {
  const notificationQueue = useLocalStorage<NotificationMessage[]>("KLEANZ_NOTIFICATIONS", [], {
    serializer: {
      read: (v) => (v ? JSON.parse(v) : null),
      write: (v) => JSON.stringify(v.filter((el) => el.persist)),
    },
  });

  const fileStore = createStore("KLEANZ_DOWNLOADS", "id:blob");

  function __fallback<T extends keyof NotificationMessage>(
    key: T,
    fallback: NotificationMessage[T],
  ): NotificationMessage[T] {
    if (notificationQueue.value.length === 0) return fallback;
    return notificationQueue.value[0][key] ?? fallback;
  }

  const currentActive = computed(() => notificationQueue.value.find((el) => el.active));

  const timeoutMap = new Map<string, ReturnType<typeof setTimeout>>();
  watch(
    currentActive,
    (val) => {
      if (val && val.timeout) {
        timeoutMap.set(
          val.id,
          setTimeout(() => {
            timeoutMap.delete(val.id);
            val.persist ? hideMessage(val) : clearMessage(val);
          }, val.timeout),
        );
      }
    },
    { deep: true, immediate: true },
  );

  const message = computed(() => __fallback("message", ""));
  const type = computed(() => __fallback("type", NotificationType.Success));
  const active = computed(() => __fallback("active", false));
  const showProgress = computed(() => __fallback("showProgress", false));
  const progress = computed(() => __fallback("progress", 0));

  const nuxtApp = useNuxtApp();

  function makeNotificationHandle(msg: Ref<NotificationMessage>): NotificationHandle {
    return {
      messageObject: msg,
      close() {
        clearMessage(msg.value);
      },
      hide() {
        hideMessage(msg.value);
      },
      cancelTimeout() {
        cancelTimeout(msg.value);
      },
      setTimeout(value?: number) {
        msg.value.timeout = value;
      },
      persist(value: boolean = true) {
        msg.value.persist = value;
      },
      async setFile(file: Blob, filename: string) {
        msg.value.hasDownload = true;
        return set(msg.value.id, { file, filename }, fileStore);
      },
      async getFile() {
        return get(msg.value.id, fileStore);
      },
      setType(val: NotificationType) {
        msg.value.seen = false;
        msg.value.type = val;
      },
      setMessage(val: string) {
        msg.value.seen = false;
        msg.value.message = val;
      },
      setProgress(val: number) {
        msg.value.seen = false;
        msg.value.showProgress = true;
        msg.value.progress = val;
      },
      hideProgress() {
        msg.value.showProgress = false;
      },
      markSeen() {
        msg.value.seen = true;
      },
    };
  }

  function getNotificationHandler(id: string) {
    const index = notificationQueue.value.findIndex((el) => el.id === id);
    if (index >= 0) {
      return makeNotificationHandle(toRef(notificationQueue.value, index));
    }
  }

  function __showMessage(
    updateType: NotificationType,
    updateMessage: string = "",
    updatePersist: boolean = false,
    updateShowProgress: boolean = false,
    updateProgress: number = 0,
  ): NotificationHandle {
    const message = new NotificationMessage(
      updateType,
      updateMessage,
      true,
      updatePersist,
      updateShowProgress,
      updateProgress,
    );
    const length = notificationQueue.value.push(message);
    return makeNotificationHandle(toRef(notificationQueue.value, length - 1));
  }

  function cancelTimeout(item?: NotificationMessage) {
    if (item) {
      item.timeout = undefined;
      if (timeoutMap.has(item.id)) {
        clearTimeout(timeoutMap.get(item.id));
        timeoutMap.delete(item.id);
      }
    }
  }

  function clearMessage(msg?: NotificationMessage) {
    if (msg) {
      const index = notificationQueue.value.findIndex((el) => el.id === msg.id);
      if (index >= 0) {
        const item = notificationQueue.value.splice(index, 1);
        cancelTimeout(item[0]);
        del(item[0].id, fileStore).then((e) => devConsole.log("file removed.", e));
      }
      return;
    }
    const item = notificationQueue.value.shift();
    if (item) {
      cancelTimeout(item);
      del(item.id, fileStore).then((e) => devConsole.log("file removed.", e));
    }
  }

  function clearAllPersist(active: boolean = false) {
    const persistent = notificationQueue.value.filter((el) => (el.persist && active ? true : !el.active));
    persistent.forEach((el) => {
      clearMessage(el);
    });
  }

  function hideMessage(msg?: NotificationMessage) {
    let item;
    if (msg) {
      item = notificationQueue.value.find((el) => el.id === msg.id);
    } else {
      item = notificationQueue.value.find((el) => el.active);
    }
    if (item) item.active = false;
  }

  function getError(message: ErrorNotificationOptions = "") {
    const reporting = useReportError(nuxtApp);
    const realMessage = toRaw(unref(message)); // Unwrapping in case it's a ref
    devConsole.dir(realMessage);
    if (realMessage instanceof LocalFetchError) {
      reporting?.captureException(realMessage);
      devConsole.error(realMessage);
      if (isNull(realMessage.body)) {
        return nuxtApp.$i18n.t("desc.defaultError");
      }
      if (realMessage.type === "error" && realMessage.body instanceof Error) {
        return realMessage.body.message;
      }
      if (realMessage.type === "json" && typeof realMessage.body === "object") {
        if (isApiErrorResponse(realMessage.body)) {
          const t = nuxtApp.$i18n.t;
          switch (realMessage.body.kind) {
            case ErrorResponses.Error:
              return realMessage.body.message;
            default:
              return t(`errors.api.${realMessage.body.kind}`);
          }
        } else {
          return JSON.stringify(realMessage.body as Record<string, string>);
        }
      }
      return <string>realMessage.body;
    }

    if (realMessage instanceof Error) {
      reporting?.captureException(realMessage);
      devConsole.error(realMessage);
      return realMessage.message;
    }
    if (isMessageObject(realMessage)) {
      return realMessage.message;
    }
    return realMessage;
  }

  function showError(message: ErrorNotificationOptions = "", persist: boolean = false) {
    // Using this as a function and passing in the current nuxtApp context.
    const handle = __showMessage(NotificationType.Error, getError(message), persist);
    handle.setTimeout(TimeInMS.TEN_SECONDS);
    return handle;
  }

  function showPending(
    message: string = "",
    showProgress: boolean = false,
    progress?: number,
    persist: boolean = true,
  ) {
    return __showMessage(NotificationType.Pending, message, persist, showProgress, progress);
  }

  function showSuccess(message: string = "", persist: boolean = false) {
    const handler = __showMessage(NotificationType.Success, message, persist);
    handler.setTimeout(TimeInMS.FIVE_SECONDS);
    return handler;
  }

  function showWarning(message: string = "", persist: boolean = false) {
    const handler = __showMessage(NotificationType.Warning, message, persist);
    handler.setTimeout(TimeInMS.FIVE_SECONDS);
    return handler;
  }

  function showDevError(...args: Parameters<typeof showError>) {
    if (import.meta.env.DEV) {
      const handle = showError(...args);
      handle.persist();
      handle.cancelTimeout();
      return handle;
    }
  }

  return {
    type,
    message,
    active,
    progress,
    clearMessage,
    clearAllPersist,
    hideMessage,
    showSuccess,
    showPending,
    showProgress,
    showError,
    showDevError,
    showWarning,
    notificationQueue: readonly(notificationQueue),
    currentActive,
    getNotificationHandler,
    getError,
  };
});
